async function fetchGraphQL(text: any, variables: any) {
  //   const REACT_APP_GITHUB_AUTH_TOKEN = process.env.REACT_APP_GITHUB_AUTH_TOKEN;

  console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  // Fetch data from GitHub's GraphQL API:
  const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
    method: "POST",
    headers: {
      //   Authorization: `bearer ${REACT_APP_GITHUB_AUTH_TOKEN}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return await response.json();
}

export default fetchGraphQL;
